import Heading from 'DesignSystem/Typography/Headings/Heading';
import { useTranslationData } from 'Shared/Providers/TranslationProvider';
import { styled } from 'Theme/stitches.config';
import PrimaryButton from 'DesignComponents/Atoms/Buttons/PrimaryButton';
import { useKexNavigate } from 'Kex/KexRouter/KexRouter';
import OrderModel from 'Models/Checkout/OrderConfirmation/OrderModel.interface';
import { getUrlParameter } from 'Shared/Common/Helpers';
import useCurrentPage from 'Shared/Hooks/useCurrentPage';
import OrderConfirmationPageModel from 'Models/Pages/OrderConfirmationPage/OrderConfirmationPageModel.interface';
import Button from 'Shared/Components/Buttons/Button';
type PropType = {
  order?: OrderModel;
  isLoading?: boolean;
  allowEdit?: boolean;
  noPadding?: boolean;
};

function OrderConfirmationInfo({ order }: PropType) {
  const navigate = useKexNavigate();
  const {
    checkoutLabels: {
      thankYouForYourOrder,
      orderConfirmationInfo,
      dateLabel,
      orderNumber,
      deliveryAddress,
    },
    cartLabels: { cartContinueShopping },
  } = useTranslationData();

  const {
    order: { purchaseDate },
  } = useCurrentPage<OrderConfirmationPageModel>();

  function capitalizeFirstLetter(string?: string) {
    string = string ? string : '';
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const shippingAddress =
    order?.checkoutFormDataModel?.shippingAndBillingAddressModel?.shipping;
  const contactDetails = order?.checkoutFormDataModel?.contactDetailsModel;
  const firstName = capitalizeFirstLetter(shippingAddress?.firstName);
  const lastName = capitalizeFirstLetter(shippingAddress?.lastName);
  const orderNumberValue = getUrlParameter('order');

  return (
    <Root>
      <Heading tag="h1" weights="semiBold" size="l">
        {`${thankYouForYourOrder}, ${firstName}`}
      </Heading>
      <OrderInfo>
        <Row>
          <div>{orderConfirmationInfo}:</div>
          <WeightSemiBold>{contactDetails?.email}</WeightSemiBold>
        </Row>
        {orderNumberValue && (
          <Row>
            {orderNumber}: <WeightSemiBold>{orderNumberValue}</WeightSemiBold>
          </Row>
        )}
        <>
          {dateLabel}: <WeightSemiBold>{purchaseDate}</WeightSemiBold>
        </>
        <Heading tag="h2" weights="semiBold" size="sm">
          {deliveryAddress}
        </Heading>
        <Row>
          {firstName} {lastName}
        </Row>
        <div>{shippingAddress?.fullAddress}</div>
        <div>
          {shippingAddress?.postalCode} {shippingAddress?.city}
        </div>
      </OrderInfo>
      <Button<typeof PrimaryButton>
        props={{
          text: cartContinueShopping,
          color: 'Regular',
          size: 'm',
          hug: 'width',
        }}
        element="PrimaryButton"
        onClick={() => navigate('/')}
      />
    </Root>
  );
}

const Root = styled('div', {
  height: 'auto',
  h2: {
    marginTop: '40px',
    marginBottom: '16px',
  },
});

const OrderInfo = styled('div', {
  mt: 3,
  marginBottom: '40px',
  fontSize: '$fontSize100',
  lineHeight: '$lh24',
});

const Row = styled('span', {
  display: 'block',
  mb: 2,
});

const WeightSemiBold = styled('span', {
  fontFamily: 'fontSemiBold',
});

export default OrderConfirmationInfo;
